<template>
   <div class="facebook">
     <HFaceBookLogin 
     v-slot="fbLogin" 
     app-id="1191450082238900" 
     @onSuccess="onSuccess" 
     @onFailure="onFailure"
     scope="email,public_profile"
     fields="id,name,email,first_name,last_name,birthday"
   >
     <span @click="fbLogin.initFBLogin" class="fb-button">
      <span class="iconfont icon-fmashangpin fontsize" style="margin-right: 5px;"></span>
      {{  $t('login.cont46') }}
    </span>
   </HFaceBookLogin>
   </div>
 </template>
 
  
 
 
 <script>
 import { HFaceBookLogin } from '@healerlab/vue3-facebook-login';
 import { TripartiteCheck } from '@/network/user.js'
 import { ElMessage } from 'element-plus'
 import store from '@/store/index.js'
 export default {
     name: 'facebook',
     data() {
       return {
         fbSignInParams: {
           scope: 'email,user_likes',
           return_scopes: true
         }
       }
     },
     components:{
       HFaceBookLogin
     },
     methods: {
       onSuccess(response) {
         console.log(response)
         TripartiteCheck(2,response.authInfo.email, response.authInfo.name, response.authInfo.id).then(res => {
             if (res) {
               if (res.code == 200 || res.code == 1) {
                   store.dispatch('getUserInfo', res.data.userInfo)
                   ElMessage({
                     message: res.msg,
                     type: 'success',
                   })
                   this.$emit("customEvent", 1)
               } else {
 
               }
             } else {
 
             }
         })
       },
       onFailure(error) {
         
       },
     },
   }
 
 </script>
 
 <style>
   .fb-button {
   display: flex;
   margin: 10px 0 10 0;
   color: white;
   background-color: #1967d2;
   border-radius: 5px;
    padding: 8px 26px;
    margin-top:10px;
   cursor: pointer;
   align-items: center;
 }
.facebook{
  display: flex;
    justify-content: center;
}
 </style>
 