import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vue3GoogleLogin from 'vue3-google-login'
import i18n from "./lang/index";
import store from './store'

import encryptUtils from './common/encryptUtils';
import TabBar from '@/components/TabBar.vue';

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('TabBar', TabBar)

app.use(vue3GoogleLogin, {
  clientId: '128225774130-v58fi19j5tcnbv55mfbmk75i4bg47in0.apps.googleusercontent.com',
});

app.config.globalProperties.$encryptUtils = encryptUtils;

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

app.use(i18n);
// app.use(ElementPlus);
const lang = i18n.global.locale.value;
if(lang == 'zh' ){
  app.use(ElementPlus, {
    locale: zhCn,
  })
}else{
  app.use(ElementPlus)
}

app.use(router);
app.use(store);
app.mount("#app");
// createApp(App).use(router).use(ElementPlus).mount('#app')

